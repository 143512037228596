import { useEffect } from 'react'

import { useLocation, useMatches } from '@remix-run/react'
import * as Sentry from '@sentry/remix'

import { getAppEnv } from '~/utils'

export function SentryInit() {
  Sentry.init({
    //@ts-ignore
    dsn: window.ENV.SENTRY_DSN,
    tracesSampler: (samplingContext) => {
      if (samplingContext.transactionContext.name === 'routes/healthcheck') {
        // Drop this transaction, by setting its sample rate to 0%
        return 0
      } else {
        // Default sample rate for all others (replaces tracesSampleRate)
        return 1
      }
    },
    environment: getAppEnv(true),
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.remixRouterInstrumentation(
          useEffect,
          useLocation,
          useMatches
        ),
      }),
      new Sentry.Replay(),
    ],
  })
}
